.about-container {
  padding: 4rem;
  background: #333;
}

.about-wrapper {
  position: relative;
  margin: 50px 0 45px;
  width: 100%;
  display: flex;
  align-items: center; /* Center items vertically */
}

.about-picture {
  padding-left: 80px;
}

.about-picture-img {
  width: 80%;
}

.about-title {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #fff;
  font-size: 42px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.about-content {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #fff;
}

@media screen and (max-width: 960px) {
  .about-content {
    font-size: 12px;
  }

  .about-wrapper {
    display: block;
  }
}
